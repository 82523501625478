<template>
  <div class="tutorials-view">
    <div class="videos">
      <div class="video" v-for="(tutorial, i) in tutorials" :key="`${i}_${tutorial.link}`">
        <iframe
          width="400"
          height="220"
          :src="`https://www.youtube.com/embed/${getYoutubeId(tutorial.link)}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

        <span>{{tutorial.data.title}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TutorialsView',
  computed: {
    ...mapGetters('tutorials', {
      tutorials: 'getTutorials'
    }),
  },
  methods: {
    ...mapActions('tutorials', {
      handleGetTutorialsWithData: 'handleGetTutorialsWithData',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    getYoutubeId(originalLink){
      const url = originalLink.split('url=')[1].split('&format=')[0].split('v=')[1]

      return url
    },
  },
  async created(){
    const breadcrumb = {
      page: 'Tutoriais',
      subPage: {
        name: 'Tutoriais',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
    await this.handleGetTutorialsWithData()
  }
}
</script>

<style lang="scss" scoped>
.tutorials-view {
  .videos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    .video {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      span {
        color: #000;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}
</style>
